<template>
  <component :is="tag" v-if="tag">
    <slot />
  </component>
  <slot v-else />
</template>

<script lang="ts" setup>
const props = defineProps<{
  // need to use id from props because `useId` is broken
  // https://github.com/nuxt/nuxt/issues/27378
  id: string
  oneAlwaysActive?: boolean
  tag?: keyof HTMLElementTagNameMap
}>()

const activeItem = ref<null | string>(null)

provide('one-always-active', props.oneAlwaysActive)
provide('group-id', props.id)
provide('active-item', activeItem)
</script>
